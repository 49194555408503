import { datadogRum } from "@datadog/browser-rum";
import type { ErrorLogOptions } from "../types";

export function logClientError(error: Error, options?: ErrorLogOptions) {
  datadogRum.addError(error, {
    type: "client",
    path: window.location.pathname,
    href: window.location.href,
    ...options
  });
}
