import type { RumEvent } from "@datadog/browser-rum";

export function excludeAuthError(event: RumEvent) {
  if (event.type === "error") {
    const accessTokenExpired = event.error.message.includes("460");
    const accessTokenNotexist = event.error.message.includes("401");
    if (accessTokenExpired || accessTokenNotexist) {
      return false;
    }
  }
  return true;
}
