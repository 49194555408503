import { type RumInitConfiguration, datadogRum } from "@datadog/browser-rum";
import type { RumInitConfigOptions } from "./types";
import { excludeAuthError } from "./utils/excludeAuthError";
import { isBrowser } from "./utils/isBrowser";

const nextConfig = (options: RumInitConfigOptions) => ({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: process.env.NEXT_PUBLIC_DATADOG_APPLICATION,
  env: process.env.NEXT_PUBLIC_DATADOG_ENV,
  version: options?.packageVersion,
  sessionSampleRate: options?.sessionSampleRate ?? 3,
  sessionReplaySampleRate: options?.sessionReplaySampleRate ?? 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [
    `${process.env.NEXT_PUBLIC_API_DOMAIN}`,
    `${process.env.NEXT_PUBLIC_CDN_DOMAIN}`
  ],
  beforeSend: options?.excludeAuthError ? excludeAuthError : null
});

const nextDatadogRumInit = (options: RumInitConfigOptions) => {
  if (isBrowser() && process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID) {
    const config = nextConfig(options) as RumInitConfiguration;
    datadogRum.init(config);
    datadogRum.startSessionReplayRecording();
  }
};

export default nextDatadogRumInit;
