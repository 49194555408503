import { type AxiosError, isAxiosError } from "axios";
import type { ErrorLogOptions } from "./types";
import { logClientError } from "./utils/logClientError";
import { logNetworkError } from "./utils/logNetworkError";

export function useErrorLogger() {
  return (error: AxiosError | Error, options?: ErrorLogOptions) => {
    if (!isAxiosError(error)) {
      logClientError(error, options);
    } else {
      logNetworkError(error, options);
    }
  };
}
