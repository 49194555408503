"use client";

import { type AxiosError, isAxiosError } from "axios";
import { useErrorLogger } from "datadog";
import { Button, Typography, WithFloatingLayout } from "design-system-ui";
import Image from "next/image";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { openOutlink } from "shared-utils";
import { settings } from "@/configs/settings";
import { AUTH_ERROR_CONTENT, ERROR_CONTENT } from "@/constants/errorContent";
import type { BikeInfoResponse } from "@/types/bike/bike.type";

const LoadingError = ({
  error,
  reset,
}: {
  error:
    | AxiosError<BikeInfoResponse>
    | (Error & { digest?: string; response: any });
  reset: () => void;
}) => {
  const isAuthorizationError = error.response?.status === 401;
  const [errorState, setErrorState] = useState("SERVER_ERROR");
  const logError = useErrorLogger();
  // TODO: 401 케이스 핸들링을 커스텀 210 에러로 처리해야함
  const { title, messages } = isAuthorizationError
    ? AUTH_ERROR_CONTENT["007-002"]
    : ERROR_CONTENT[errorState];
  logError(error);

  const isNetworkError = isAxiosError(error);
  const isChunkLoadError = error.message
    .toLowerCase()
    .includes("chunkloaderror");

  const refreshChunkError = () => {
    const cookies = new Cookies();
    const chunkRefeshed = cookies.get("chunkRefeshed");
    if (chunkRefeshed) return;

    cookies.set("chunkRefeshed", true, { path: "/", maxAge: 60 });
    reset();
    window.location.reload();
  };

  useEffect(() => {
    if (isChunkLoadError) refreshChunkError();

    isNetworkError
      ? setErrorState("SERVER_ERROR")
      : setErrorState("CLIENT_ERROR");
  }, []);

  return (
    <WithFloatingLayout
      floatingUnit={
        isAuthorizationError ? (
          <Button
            buttonType={"button-fill-normal-primary"}
            onClick={() => openOutlink(settings.DEEPLINK_URL)}
            size="large"
          >
            <Typography variant="title2">쏘카일레클 설치하러 가기</Typography>
          </Button>
        ) : null
      }
    >
      <div className="tw-px-20 tw-text-center tw-justify-center tw-items-center tw-pt-[calc((100vh/2)-100px)] tw-relative">
        <Typography
          variant="title1"
          color="gray700"
          as="div"
          className="tw-mb-8"
        >
          {title}
        </Typography>

        {messages?.map((message: string, index: number) => {
          return (
            <Typography
              variant="body3"
              color="gray500"
              as="div"
              className={`tw-whitespace-pre-line ${
                messages.length > 1 ? "tw-mt-20" : ""
              }`}
              key={message}
            >
              <div key={index}>{message}</div>
            </Typography>
          );
        })}
        <Image
          className="tw-block tw-m-auto tw-mt-44"
          src={`${settings.CDN_DOMAIN}/etc/error_bike.webp`}
          width={220}
          height={120}
          alt="이용불가 자전거 아이콘"
        />
      </div>
    </WithFloatingLayout>
  );
};

export default LoadingError;
