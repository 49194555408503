import { datadogRum } from "@datadog/browser-rum";
import type { AxiosError } from "axios";
import type { ErrorLogOptions } from "../types";

export function logNetworkError(error: AxiosError, options?: ErrorLogOptions) {
  datadogRum.addError(error, {
    type: "network",
    statusCode: error?.response?.status,
    resource: error?.config?.url,
    method: error?.config?.method,
    data: error?.config?.data,
    response: error?.response?.data,
    pathname: window.location.pathname,
    href: window.location.href,
    ...options
  });
}
