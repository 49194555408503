import { type RumInitConfiguration, datadogRum } from "@datadog/browser-rum";
import type { RumInitConfigOptions } from "./types";
import { excludeAuthError } from "./utils/excludeAuthError";
import { isBrowser } from "./utils/isBrowser";

const nuxtConfig = (options: RumInitConfigOptions) => ({
  applicationId: process.env.DATADOG_APPLICATION_ID,
  clientToken: process.env.DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: process.env.DATADOG_APPLICATION,
  env: process.env.DATADOG_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  version: options?.packageVersion,
  sessionSampleRate: options?.sessionSampleRate ?? 3,
  sessionReplaySampleRate: options?.sessionReplaySampleRate ?? 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  allowedTracingUrls: [
    `${process.env.API_DOMAIN}`,
    `${process.env.VEHICLE_API_DOMAIN}`,
    `${process.env.CDN_DOMAIN}`
  ],
  defaultPrivacyLevel: "mask-user-input",
  traceSampleRate: 100,
  beforeSend: options?.excludeAuthError ? excludeAuthError : null
});

const nuxtDatadogRumInit = (options: RumInitConfigOptions) => {
  if (isBrowser() && process.env.DATADOG_APPLICATION_ID) {
    const config = nuxtConfig(options) as RumInitConfiguration;
    datadogRum.init(config);
    datadogRum.startSessionReplayRecording();
  }
};

export default nuxtDatadogRumInit;
